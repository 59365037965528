import React from 'react';


function LogoIcon({ darkMode }) {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="1.42222in" height="1.42222in" viewBox="0 0 128 128" style={{ width: '42px', height: '42px' }}>
  <path id="path854" stroke="black" strokeWidth="1" f fill={darkMode ? '#FFF' : '#F00'}
        d="M 81.27,51.53
           C 93.98,52.05 103.11,61.77 103.69,76.92
             104.28,92.06 93.34,104.72 79.25,105.19
             65.17,105.66 53.28,93.77 52.69,78.63
             52.40,71.05 55.72,60.24 63.49,56.11
             60.23,52.33 53.06,51.32 49.03,51.58
             42.75,60.18 39.40,73.38 41.80,86.84
             42.79,92.42 43.21,98.12 51.41,106.90
             15.71,105.75 19.47,50.68 48.94,51.56
             59.20,52.52 69.78,56.43 71.64,66.68
             72.09,69.75 72.51,71.83 72.17,77.90
             66.53,77.85 67.60,78.09 61.35,77.72
             64.40,81.68 78.23,94.33 79.10,95.04
             81.67,93.17 89.68,85.67 98.84,78.24
             95.58,78.33 88.00,78.13 88.00,78.13
             88.00,78.13 87.12,70.87 86.82,68.03
             86.06,60.91 84.03,58.24 81.44,51.72
             81.03,50.69 72.88,41.28 66.22,39.15
             -6.22,18.04 -7.41,130.69 55.79,111.68
             59.98,116.11 74.78,116.81 78.81,116.65
             106.51,115.52 116.35,92.74 115.35,69.72
             115.35,69.72 115.35,69.72 115.35,69.72
             115.35,69.72 115.41,17.25 115.41,17.25
             106.23,17.70 103.59,20.08 103.88,34.67
             104.00,41.28 104.23,49.75 104.23,49.75
             104.23,49.75 95.01,36.97 73.89,38.36
             69.21,38.66 71.27,39.18 65.82,39.12
             70.90,41.07 76.20,44.37 81.27,51.53
             81.27,51.53 81.27,51.53 81.27,51.53 Z
           M 40.40,0.83
           C 40.40,0.83 87.54,0.83 87.54,0.83
             109.25,0.83 126.73,18.31 126.73,40.02
             126.73,40.02 126.73,87.16 126.73,87.16
             126.73,108.87 109.25,126.35 87.54,126.35
             87.54,126.35 40.40,126.35 40.40,126.35
             18.69,126.35 1.21,108.87 1.21,87.16
             1.21,87.16 1.21,40.02 1.21,40.02
             1.21,18.31 18.69,0.83 40.40,0.83
             40.40,0.83 40.40,0.83 40.40,0.83 Z
           M 2565.60,456.38
           C 2565.60,456.38 2565.60,1730.99 2565.60,1730.99
             2565.60,1730.99 1028.86,1730.99 1028.86,1730.99
             1028.86,1730.99 1028.86,456.38 1028.86,456.38
             1028.86,456.38 2565.60,456.38 2565.60,456.38 Z
           M -134.38,-626.24
           C -134.38,-626.24 -134.88,-626.24 -134.88,-626.24
             -134.88,-625.89 -134.38,-624.85 -134.38,-624.51
             -134.38,-624.85 -133.88,-625.89 -133.88,-626.24
             -133.88,-626.24 -134.38,-626.24 -134.38,-626.24 Z"/>
</svg>
  );
}

export default LogoIcon;
